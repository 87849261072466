<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            prepend-icon="search"
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <router-link :to="{ name: 'cadastro-cargo' }" class="nounderline">
            <v-btn icon>
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :search="search" :items="cargos" hide-actions class="elevation-0">
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.descricao }}</td>
            <td class="text-xs-right">
              <router-link :to="{ name: 'edit-cargo', params: { id: props.item.id } }" class="nounderline">
                <v-btn depressed icon dark color="primary" small>
                  <v-icon small>edit</v-icon>
                </v-btn>
              </router-link>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import { ServicoCargo } from "../../servicos/servicoCargo"
const servicoCargo = new ServicoCargo()
export default {
  data() {
    return {
      search: "",
      cargos: [],
      headers: [
        {
          text: "Código",
          align: "left",
          value: "id"
        },
        { text: "Cargo", align: "left", value: "descricao" },
        { text: "", value: "action", align: "right" }
      ]
    }
  },
  created() {
    servicoCargo.buscarCargos().then(
      res => {
        if (res.status === 200) {
          this.cargos = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
